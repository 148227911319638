import { render, staticRenderFns } from "./ImmortalityClan.vue?vue&type=template&id=2bc7e571&scoped=true&"
import script from "./ImmortalityClan.vue?vue&type=script&lang=js&"
export * from "./ImmortalityClan.vue?vue&type=script&lang=js&"
import style0 from "./ImmortalityClan.vue?vue&type=style&index=0&id=2bc7e571&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/.store/vue-loader@15.10.1/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bc7e571",
  null
  
)

export default component.exports